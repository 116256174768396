/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if($('.js-hero-video').length > 0) {

    function refreshObjectFit() {
      $('.js-hero-video').each(function() {
        var $this = $(this);

        var containerWidth = $this.innerWidth();
        var containerHeight = $this.innerHeight();

        if (containerWidth/containerHeight < 16/9) {
          var videoWidth = 16*containerHeight/9;
          $(this).find('iframe').css('width', videoWidth + 'px').css('height', '100%');
        }
        else {
          var videoHeight = containerWidth*9/16;
          $(this).find('iframe').css('width', '100%').css('height', videoHeight + 'px');
        }
      });
    }
    refreshObjectFit();

    var $window = $(window);
    var windowWidth = $window.width();
    var timeout = null;
    $(window).on('resize', function() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(function() {
        var newWindowWidth = $window.width();
        if (windowWidth != newWindowWidth) {
          windowWidth = newWindowWidth;
          refreshObjectFit();
        }
      }, 300);
    })

  }

})();
