/**
** JAVASCRIPTS
** Name: Equipments
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-equipments').length > 0) {

    $('.js-equipments').each(function() {
      var $equipments = $(this),
          $slider     = $equipments.find('.js-equipments-slider'),
          $prev       = $equipments.find('.js-equipments-prev'),
          $next       = $equipments.find('.js-equipments-next');

      $slider.slick({
        autoplay: false,
        speed: 500,
        infinite: false,
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 719,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 959,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });

      // ON CHANGE
      $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if(nextSlide == 0) {
          $prev.addClass('is-hidden');
          $next.removeClass('is-hidden');
        }
        else {
          $prev.removeClass('is-hidden');

          if(nextSlide == (slick.slideCount - slick.options.slidesToShow)) {
            $next.addClass('is-hidden');
          }
          else {
            $next.removeClass('is-hidden');
          }
        }
      });

    });

  }

})();
