/**
** JAVASCRIPTS
** Name: Tech
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-tech')) {


    /**
    ** Variables
    ****************************************/

    const $techs = document.querySelectorAll('.js-tech');


    /**
    ** Events
    ****************************************/

    $techs.forEach(function($tech) {
      const $button = document.querySelector('.js-tech-button');

      $button.addEventListener('click', (event)=>{
        event.preventDefault();
        $tech.classList.add('is-deployed');
      });

    });


  }

})();
