/**
** JAVASCRIPTS
** Name: Memo
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-memo')) {


    /**
    ** Variables
    ****************************************/

    const $memo = document.querySelector('.js-memo');
    var checkpoint = 1000;

    if(document.querySelector('.js-memo-checkpoint')) {
      checkpoint = document.querySelector('.js-memo-checkpoint').offsetTop;
    }


    /**
    ** Events
    ****************************************/

    window.onscroll = function(event) {  
      if(window.scrollY > checkpoint) {
        $memo.classList.add('is-active');
      }
      else {
        $memo.classList.remove('is-active');
      }
    } 


  }

})();
