/**
** JAVASCRIPTS
** Name: Overlay
********************************************************************************/

document.addEventListener('DOMContentLoaded', () => {
  'use strict';

  if(document.querySelectorAll('.js-overlay').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $html  = document.querySelector('html');
    const $opens = document.querySelectorAll('.js-overlay-open');
    const $closes = document.querySelectorAll('.js-overlay-close');


    /**
    ** Events
    ****************************************/

    $opens.forEach(function($open) {
      const target  = $open.dataset.target;
      const $target = document.querySelector(`.js-overlay[data-id="${target}"]`);

      if($target) {
        $open.addEventListener('click', (event)=>{
          event.preventDefault();
          $target.classList.add('is-visible');
          $html.classList.add('overlayed');
        });
      }
    });

    $closes.forEach(function($close) {
      const $target = $close.closest('.js-overlay');

      if($target) {
        $close.addEventListener('click', (event)=>{
          event.preventDefault();

          if($target.querySelectorAll('iframe')) {
            const $iframes = $target.querySelectorAll('iframe');
            $iframes.forEach(function($iframe) {
              const source = $iframe.src;
              $iframe.src = '';
              $iframe.src = source;
            });
          }

          $target.classList.remove('is-visible');
          $html.classList.remove('overlayed');
        });
      }
    });


  }

});
