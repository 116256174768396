/**
** JAVASCRIPTS
** Name: Press
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-press').length > 0) {

    $('.js-press').each(function() {
      var $press    = $(this),
          $slider = $press.find('.js-press-slider'),
          $prev   = $press.find('.js-press-prev'),
          $next   = $press.find('.js-press-next');

      $slider.slick({
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 1000,
        infinite: true,
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });

    });

  }

})();
