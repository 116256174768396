/**
** JAVASCRIPTS
** Name: Search
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-search').length > 0) {


    /**
    ** DOM Content Loaded
    ****************************************/

    document.addEventListener('DOMContentLoaded', function() {


      /**
      ** Variables
      ****************************************/

      const searchs = document.querySelectorAll('.js-search');
      const html    = document.querySelector("html");
      const country = html.getAttribute("data-country");
      let hasScript = false;


      /**
      ** FUNCTION
      ** Append script
      ****************************************/

      const appendScript = function(key) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&libraries=places&callback=initAutocomplete';
        script.addEventListener('load', () => {
          initAutocomplete;
        }, { once: true });
        document.body.appendChild(script);
      }


      /**
      ** Actions
      ****************************************/

      searchs.forEach((element) => {


        /**
        ** Variables
        ****************************************/

        const searchType = element.dataset.searchType;
        const searchInput = element.querySelector('.js-search-input');
        const latInput = element.querySelector('.js-search-lat');
        const lngInput = element.querySelector('.js-search-lng');
        let isCompleted = false;
        let hasDownBeenPressed = false;
        let autocomplete = null;


        /**
        ** Functions
        ****************************************/

        const initAutocomplete = function() {


          /**
          ** Google variables
          ****************************************/

          if(country == "be" || country == "lu") {
            var countries = ["be", "lu"];
          }
          else {
            var countries = [];
          }

          var options = {
            types: ['(regions)'],
            componentRestrictions: { 'country': countries }
          };

          autocomplete = new google.maps.places.Autocomplete(searchInput, options);


          /**
          ** On place change
          ****************************************/

          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();

            if (place.geometry) {
              latInput.value = place.geometry.location.lat();
              lngInput.value = place.geometry.location.lng();
              isCompleted = true;
              hasDownBeenPressed = false;
            }
          });
        };


        /**
        ** Keydown
        ****************************************/

        searchInput.addEventListener('keydown', (e) => {
          if (e.keyCode === 40) {
            hasDownBeenPressed = true;
          }
        });

        searchInput.addEventListener('keydown', (e) => {
          e.cancelBubble = true;

          if (e.keyCode === 13) {
            e.preventDefault();
          }

          if (e.keyCode === 13 || e.keyCode === 9) {
            if (!hasDownBeenPressed && !e.hasRanOnce) {
              const event = new Event('keydown');
              event.code = "ArrowDown";
              event.key = "ArrowDown";
              event.keyCode = 40;
              event.hasRanOnce = true;
              google.maps.event.trigger(e.target, 'keydown', event);
            }
          }
        });


        /**
        ** Init
        ****************************************/

        window.initAutocomplete = initAutocomplete;

        if(!hasScript) {
          appendScript(searchInput.dataset.key);
          hasScript = true;
        }

      });


    });

  }


})();
