/**
** JAVASCRIPTS
** Name: Abstract
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-abstract').length > 0) {

    $('.js-abstract').each(function() {
      var $abstract = $(this),
          $slider   = $abstract.find('.js-abstract-slider'),
          $prev     = $abstract.find('.js-abstract-prev'),
          $next     = $abstract.find('.js-abstract-next');

      $slider.slick({
        autoplay: false,
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'c-abstract__dots',
        mobileFirst: true
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });


      // VEHICLES

      if($abstract.find('.js-abstract-button')) {
        var $button = $abstract.find('.js-abstract-button'),
            $more   = $abstract.find('.js-abstract-more'),
            $plus   = $abstract.find('.js-abstract-plus'),
            $items  = $abstract.find('.js-abstract-item.is-hidden');

        $button.on('click', function(event) {
          event.preventDefault();
          $more.remove();
          $plus.remove();
          $items.removeClass('is-hidden');
        });
      }


    });

  }

})();
