/**
** JAVASCRIPTS
** Name: Gallery
********************************************************************************/


/**
** Pre-imports
****************************************/

import Parvus from "parvus";


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  document.addEventListener('DOMContentLoaded', function() {
    const prvs = new Parvus({
      gallerySelector: ".js-gallery",
    });
  });
  
})();
