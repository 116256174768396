/**
** JAVASCRIPTS
** Name: Day
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';


  // Projects slider

  if($('.js-day').length > 0) {

    $('.js-day').each(function() {
      var $day    = $(this),
          $slider = $day.find('.js-day-slider'),
          $prev   = $day.find('.js-day-prev'),
          $next   = $day.find('.js-day-next');

      $slider.slick({
        autoplay: false,
        // autoplaySpeed: 4000,
        speed: 1000,
        infinite: false,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });

      // ON CHANGE
      $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if(nextSlide == 0) {
          $day.attr('data-current', "morning");
        }
        else if(nextSlide == 1) {
          $day.attr('data-current', "afternoon");
        }
        else if(nextSlide == 2) {
          $day.attr('data-current', "evening");
        }
      });

    });

  }

})();
