/**
** JAVASCRIPTS
** Name: Slider
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-slider').length > 0) {

    $('.js-slider').each(function() {
      var $slider = $(this),
          $items  = $slider.find('.js-slider-items'),
          $prev   = $slider.find('.js-slider-prev'),
          $next   = $slider.find('.js-slider-next');

      let firstDuration = 5000;
      let firstSlide = document.querySelectorAll(".c-slider__item");

      if (firstSlide[0].hasAttribute("data-duration")) {
        firstDuration = firstSlide[0].getAttribute("data-duration") * 1000;
      }

      $items.slick({
        autoplay: true,
        autoplaySpeed: firstDuration,
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'c-slider__dots',
        mobileFirst: true
      });

      // PREV
      $prev.on('click', function() {
        $items.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $items.slick('slickNext');
      });

      $slider.on("afterChange", function(e, slick){
        let activeElement = document.querySelector(".slick-current");
        console.log(activeElement );
        let activeElementContent = activeElement.querySelector('.c-slider__item');

        if (activeElementContent.hasAttribute("data-duration")) {
          let videoDuration = activeElementContent.getAttribute("data-duration") * 1000;
          $items.slick('setOption', 'autoplaySpeed', videoDuration);
        } else {
          $items.slick("setOption", "autoplaySpeed",5000);
        }


        // console.log(activeElement);
        // let iframe = activeElement.querySelector("iframe");
        // console.log(iframe);
        // var iframes = document.querySelectorAll('iframe');
        //   Array.prototype.forEach.call(iframes, iframe => {
        //     iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
        //   func: 'stopVideo' }), '*');
        // });
        // iframe.contents().find('video').each(function ()
        // {
        //     console.log(this);
        //     // this.currentTime = 0;
        //     // this.pause();
        // });
        // iframe.pause();
      });

    });

  }

})();
