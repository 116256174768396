/**
** JAVASCRIPTS
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-navigation')) {


    /**
    ** Variables
    ****************************************/

    const $navigation = document.querySelector('.js-navigation');
    const $items      = document.querySelectorAll('.js-navigation-item');
    const $buttons    = document.querySelectorAll('.js-navigation-button');
    const $backs      = document.querySelectorAll('.js-navigation-back');


    /**
    ** Events
    ****************************************/

    $buttons.forEach(function($button) {
      const $item = $button.closest('.js-navigation-item');

      $button.addEventListener('click', (event)=>{
        event.preventDefault();

        $items.forEach(function($currentItem) {
          if($currentItem != $item) {
            $currentItem.classList.remove('is-active');
          }
        });

        $item.classList.toggle('is-active');
      });
    });

    $backs.forEach(function($back) {
      const $item = $back.closest('.js-navigation-item');

      $back.addEventListener('click', (event)=>{
        event.preventDefault();
        $item.classList.remove('is-active');
      });
    });


  }

})();
