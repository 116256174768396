/**
** JAVASCRIPTS
** Name: Overview
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-overview')) {


    /**
    ** Variables
    ****************************************/

    const $overview = document.querySelector('.js-overview');
    const $pictures = document.querySelectorAll('.js-overview-picture');
    const $colors   = document.querySelectorAll('.js-overview-color');


    /**
    ** Events
    ****************************************/

    $colors.forEach(function($color) {

      $color.addEventListener('click', (event)=>{
        event.preventDefault();

        const id = $color.dataset.id;

        $pictures.forEach(function($picture) {
          if($picture.dataset.id != id) {
            $picture.classList.remove('is-active');
          }
          else {
            $picture.classList.add('is-active');
          }
        });

        $colors.forEach(function($color) {
          if($color.dataset.id != id) {
            $color.classList.remove('is-active');
          }
          else {
            $color.classList.add('is-active');
          }
        });
      });

    });


  }

})();
