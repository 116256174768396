/**
** JAVASCRIPTS
** Name: Markets
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-markets')) {


    /**
    ** Variables
    ****************************************/

    const $markets = document.querySelector('.js-markets');
    const $button  = $markets.querySelector('.js-markets-button');


    /**
    ** Events
    ****************************************/

    $button.addEventListener('click', (event)=>{
      event.preventDefault();
      $markets.classList.toggle('is-active');
    });


  }

})();
