/**
** JAVASCRIPTS
** Name: Dimensional
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-dimensional').length > 0) {

    $('.js-dimensional').each(function() {
      var $dimensional = $(this),
          $visualizers = $dimensional.find('.js-dimensional-visualizer'),
          $colors      = $dimensional.find('.js-dimensional-color');


      // VISUALIZERS

      $visualizers.each(function() {
        var $visualizer = $(this),
            $slider     = $visualizer.find('.js-dimensional-slider'),
            $prev       = $visualizer.find('.js-dimensional-prev'),
            $next       = $visualizer.find('.js-dimensional-next');

        $slider.slick({
          autoplay: false,
          speed: 400,
          infinite: true,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          mobileFirst: true
        });

        // PREV
        $prev.on('click', function() {
          $slider.slick('slickPrev');
        });

        // NEXT
        $next.on('click', function() {
          $slider.slick('slickNext');
        });
      });


      // COLORS

      $colors.each(function() {
        var $color  = $(this),
            id      = $color.data('id'),
            $target = $('.js-dimensional-visualizer[data-id="' + id + '"]');

        if($target) {
          $color.on('click', function(event) {
            event.preventDefault();

            $('.js-dimensional-visualizer').not($target).removeClass('is-active');
            $target.addClass('is-active').find('.js-dimensional-slider').slick('reinit');

            $('.js-dimensional-color').not($color).removeClass('is-active');
            $color.addClass('is-active');
          });
        }
      });


    });

  }

})();
